import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Image from "../../components/image";

const CardComponent = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.breakpoints.xs}) {
    width: 330px;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

const CardImage = styled(Image)`
  margin-bottom: 40px;
`;

const CardContent = styled.div`
  background: ${props => props.theme.colors.offWhite};
  padding: 20px 15px;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    background: none;
    padding: 0;
  }
`;

const CardName = styled.h2`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 31px;
  line-height: 38px;
  margin-bottom: 15px;
`;

const CardDescriptor = styled.p`
  font-family: ${props => props.theme.fonts.condensedMedium};
  text-transform: uppercase;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 1px;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 60%;
  }
`;

const ProfilesCard = ({
  profile,
  profileNameColor,
  profileDescriptorColor,
}) => {
  return (
    <CardComponent>
      <CardImage image={profile.image} />
      <CardContent>
        <CardName
          css={css`
            color: ${profileNameColor};
          `}
        >
          {profile.name}
        </CardName>
        <CardDescriptor
          css={css`
            color: ${profileDescriptorColor};
          `}
        >
          {profile.descriptor}
        </CardDescriptor>
      </CardContent>
    </CardComponent>
  );
};

export default ProfilesCard;
