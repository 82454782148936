import React from "react";
import styled from "@emotion/styled";
import InnerContainer from "../../components/InnerContainer";
import Descriptor from "../../components/Descriptor";
import Image from "../../components/image";
import { css } from "@emotion/react";
import ProfilesCard from "./ProfilesCard";
import CtaButton from "../../components/CtaButton";

const ProfileComponent = styled.div`
  padding: 3rem 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 6rem;
    display: flex;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
`;
const ProfileLeft = styled.div`
  /* width: 50%; */
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* width: 25.78125%; */
    width: 17rem;
    max-width: 17rem;
    margin-right: 10rem;
  }
`;

const DescriptorImage = styled(Image)`
  height: 5.45rem;
  width: 5.45rem;
  margin: 3rem 0;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const ProfileRight = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* width: 59.84375%;
    margin-right: 14.375%; */
    max-width: 99rem;
  }
  /* @media screen and (min-width: ${props => props.theme.breakpoints.xl}) {
    width: 50%;
  } */
`;

const ProfileTitle = styled.h2`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 4rem;
  line-height: 50px;
  margin-bottom: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 6.5rem;
    line-height: 80px;
    margin-bottom: 3.5rem;
  }
`;

const ProfileSubtitle = styled.p`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 2.3rem;
  line-height: 35px;
  margin-bottom: 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.5rem;
    line-height: 39px;
    margin-bottom: 4rem;
  }
`;

const ProfileCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 40px;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Profiles = props => {
  return (
    <ProfileComponent
      css={css`
        background-color: ${props.backgroundColor};
      `}
    >
      <InnerContainer>
        <FlexContainer>
          <ProfileLeft>
            <Descriptor
              title={props.descriptor}
              color={props.descriptorColor}
              alignment={props.underlineAlignment}
              align="center"
            />
            <DescriptorImage image={props.image} />
          </ProfileLeft>
          <ProfileRight>
            <ProfileTitle
              css={css`
                color: ${props.titleColor};
              `}
            >
              {props.title}
            </ProfileTitle>
            {props.subtitle && (
              <ProfileSubtitle
                css={css`
                  color: ${props.subtitleColor};
                `}
              >
                {props.subtitle}
              </ProfileSubtitle>
            )}
            <ProfileCardGrid>
              {props.profiles?.map((profile, i) => (
                <ProfilesCard
                  key={i}
                  profile={profile}
                  profileNameColor={props.profileNameColor}
                  profileDescriptorColor={props.profileDescriptorColor}
                />
              ))}
            </ProfileCardGrid>
            {props.buttonLink && (
              <CtaButton
                linkType={props.buttonLinkType}
                email={props.buttonEmail}
                text={props.buttonText}
                type={props.buttonType}
                color={props.buttonColor}
                link={props.buttonLink}
              />
            )}
          </ProfileRight>
        </FlexContainer>
      </InnerContainer>
    </ProfileComponent>
  );
};

export default Profiles;
